/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { window } from 'browser-monads';

type Props = {
  title: string;
  description?: string;
  keywords?: string | string[];
  lang?: string;
  meta?: Array<HTMLMetaElement>;
  canonical?: {
    origin?: string;
    path?: string;
  };
};

const { location } = window;

const SEO = ({
  title,
  description,
  keywords,
  lang = 'en',
  meta = [],
  canonical = {
    origin: location.origin,
    path: location.pathname,
  },
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaKewords = (keywords || site.siteMetadata.keywords).toString();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKewords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: `canonical`,
          href: `${canonical.origin}${canonical.path}`,
        },
      ]}
    />
  );
};

export default SEO;
